import axios from 'axios'

// create an axios instance
const service = axios.create({
  baseURL:'https://qoyliq.gohouse.uz/api/landing_page/',
  timeout: 20000
});

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.status !== 200) {
      return Promise.reject(response)
    } else {
      return Promise.resolve(response)
    }
  },
  error => {
    return Promise.reject(error)
  }
);

export default service
