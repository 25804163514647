import { model } from "./properties/model";

export const mutations = {
    SET_LIST: (state, layouts) => (state.list = layouts),
    SET_MODEL: (state, model) => {
        state.model.id = model.id;
        state.model.house_id = model.house_id;
        state.model.name = model.name;
        state.model.image = model.image;
        state.floors = model.floors ? model.floors : [];
    },
    SET_FLOOR_NUMBERS: (state, floor) => {
        state.floorNumbers = floor;
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    SET_DIALOG_VISIBLE: (state, val) => {
        state.drawerShow = val;

    },
    SET_AREA_ID: (state, elem) => {
        state.markId = elem.elem;
        state.currentFloor = elem.val;
    }
};
