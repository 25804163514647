import request from '../utils/request'

export function showAreaOne(id, floor) {
    return request({
        url: `/areas/showAreaOne/${id}/${floor}`,
        method: 'get'
    })
}

export function inventory(layout_id) {
    return request({
        url: `/areas/inventory/${layout_id}`,
        method: 'get'
    })
}

export function showStatuses(data) {
    return request({
        url: '/areas/showStatuses',
        method: 'post',
        data
    })
}

export function sendClientInfo(data) {
    return request({
        url: '/areas/sendClientInfo',
        method: 'post',
        data
    })
}