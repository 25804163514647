import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/blocks',
    name: 'blocks',
    component: function () {
      return import('../views/Blocks.vue')
    },
    // meta: { bodyClass: 'bg-light' },
  },
  {
    path: '/block2',
    name: 'block2',
    component: function () {
      return import('../views/Block2.vue')
    },
  },
  {
    path: '/block3',
    name: 'block3',
    component: function () {
      return import('../views/Block3.vue')
    },
  },
  {
    path: '/information',
    name: 'information',
    component: function () {
      return import('../views/Information.vue')
    },
  },
  {
    path: '/aboutProject',
    name: 'aboutProject',
    component: function () {
      return import('../views/AboutProject.vue')
    },
  },
  {
    path: '/developer',
    name: 'developer',
    component: function () {
      return import('../views/Developer.vue')
    },
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: function () {
      return import('../views/Gallery.vue')
    },
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: function () {
      return import('../views/Contacts.vue')
    },
  },
  {
    path: '/offer',
    name: 'offer',
    component: function () {
      return import('../views/Offer.vue')
    },
  },
  {
    path: '/listOfBlocks',
    name: 'listOfBlocks',
    component: function () {
      return import('../views/ListOfBlocks.vue')
    },
  },

  {
    path: '/sellP/sellFloors/:id',
    component: () =>
        import ('../views/sellFloors/index'),
    name: 'sellFloors',
    meta: {
        title: 'Медиа-материалы',
    },
    children: [{
        path: '/sellP/sellFloors/:id/:floor/:value/:block_number/:block_type',
        component: () =>
            import ('../views/sellFloors/floor'),
        name: 'sellFloors.showFloor',
        meta: {
            title: 'Медиа-материалы',
        }
    }]
  }
]

const router = new VueRouter({
  routes
})

export default router
