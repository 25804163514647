import { showAreaOne, inventory, showStatuses, sendClientInfo } from '../../../api/areas';
export const actions = {


    inventory({ commit }, layout_id) {
        return new Promise((resolve, reject) => {
            inventory(layout_id).then(res => {
                commit("SET_LIST", res.data.result.data.areas.data);
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    showAreaOne({ commit }, floor) {
        return new Promise((resolve, reject) => {
            showAreaOne(floor.id, floor.currentF).then(res => {
                commit("area/SET_FLAT_LIST", res.data.result.data.flat, { root: true });
                commit("area/SET_PLAN_IMG", res.data.result.data.plan_img, { root: true });
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    showStatuses({ commit }, data) {
        return new Promise((resolve, reject) => {
            showStatuses(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    sendClientInfo({ commit }, data) {
        return new Promise((resolve, reject) => {
            sendClientInfo(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    }
};