import { show, inventory } from '../../../api/layouts';

export const actions = {
    inventory({ commit }, house_id) {
        
        return new Promise((resolve, reject) => {
            inventory(house_id).then(res => {
                commit("SET_LIST", res.data.result.data.layouts.data);
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id).then(res => {
                commit("SET_MODEL", res.data.result.data.layout);
                commit("SET_FLOOR_NUMBERS", res.data.result.data.floor);
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    }
};