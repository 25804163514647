import request from '../utils/request'

export function show(id) {
    return request({
        url: `/layouts/${id}`,
        method: 'get'
    })
}
export function inventory(house_id) {
    return request({
        url: `/layouts/inventory/${house_id}`,
        method: 'get'
    })
}
