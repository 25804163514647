import { model } from "./properties/model";

export const state = {
    list: [],
    model: JSON.parse(JSON.stringify(model)),
    floorNumbers: [],
    floors: [],
    drawerShow: false,
    markId: null,
    areas: [],
    currentFloor: null,
};