export const getters = {
    list: state => state.list,
    model: state => state.model,
    floorNumbers: state => state.floorNumbers,
    floors: state => state.floors,
    drawerShow: state => state.drawerShow,
    markId: state => state.markId,
    areas: state => state.areas,
    currentFloor: state => state.currentFloor

};