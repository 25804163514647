<template>
  <div>
    <div id="loader">
        <div class="overlay">
            <div class="overlayDoor"></div>
            <div class="overlayContent">
                <div class="loader">
                    <span>
                        <img src="../../public/img/logo-g.png" alt="">
                    </span>
                </div>
            </div>
        </div>
    </div>

    <header class="header-my">
        <div class="container ">
            <div class="h-top">
                <div class="languages w-300">
                    <div class="left-bar-menu">
                        <span><i class="fa-solid fa-bars"></i> Меню </span> 
                        <nav id="left-bar" class="left-bar">
                            <div class="left-login">
                                <img src="../../public/img/logo-g.png" alt="">
                            </div>
                            <div class="len links-blocks">
                                <a href="#" class="actione">uz</a>
                                <a href="#">ru</a>
                                <a href="#">en</a>
                            </div>
                            <div class="b-top">
                                <div class="menu-site">
                                    <router-link :to="{ name: 'home' }">
                                        <span>Главная</span>
                                    </router-link>
                                    <router-link :to="{ name: 'blocks' }">
                                        <span>Блоки</span>
                                    </router-link>
                                    <router-link :to="{ name: 'information' }">
                                        <span>Информация</span>
                                    </router-link>
                                    <router-link :to="{ name: 'aboutProject' }">
                                        <span>О проекте</span>
                                    </router-link>
                                    <router-link :to="{ name: 'developer' }">
                                        <span>Застройщик</span>
                                    </router-link>
                                    <router-link :to="{ name: 'gallery' }">
                                        <span>Галерея</span>
                                    </router-link>
                                    <router-link :to="{ name: 'contacts' }">
                                        <span>Контакты</span>
                                    </router-link>
                                    <router-link :to="{ name: 'offer' }">
                                        <span>Оферта </span>
                                    </router-link>
                                </div>
                                <div class="tel">
                                    <a href="#">+99893 870 99 99</a>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div class="mobil-menu haburger click">
                        <i class="fa-solid fa-bars"></i> Меню 
                    </div>
                </div>
                <div class="logo">
                    <router-link :to="{ name: 'home' }">
                        <img src="../../public/img/logo.png" alt="">
                    </router-link>
                </div>
                <div class="social-networks  w-320">
                    <a class="h-tel-number" href="#"><i class="fa-solid fa-phone"></i> <span>+99893 8709999</span> </a>
                    <router-link :to="{ name: 'listOfBlocks' }">
                        <i class="fa-solid fa-map-location-dot"></i>
                    </router-link>

                    <a href="#"><i class="fa-brands fa-instagram"></i></a> 
                    <a href="#"><i class="fa-brands fa-facebook"></i></a> 
                    <a href="#"><i class="fa-brands fa-telegram"></i></a> 
                </div>
            </div>
        </div>
    </header>

    <main>
        <section id="center-h-full">
            <div class="page-hero">
                <div class="slider_main_block" 
                    style="background-size:cover;background-position:bottom center;" 
                    data-tablet="img/slider/a0.jpg" 
                    data-desktop="img/slider/a0.jpg"
                    data-mobile="img/slider/a0.jpg">
                    <div>
                        <div class="site-slider__content app_slide">
                            <div class="container">
                                <div class="site-slider__title">QO’YLIQ KATTA BOZORI</div>
                                <div class="site-slider__description">
                                    Масштабный проект Qo’yliq Katta Bozori является одним из<br>
                                    крупнейших торговых комплексов в Узбекистане и<br>
                                    располагается на земельном участке более чем в 50 гектаров.
                                </div>
                                <div class="app_links d-flex flex-wrap pt-3">
                                    <router-link class="button-main-slider ing_prt8" tabindex="0" :to="{ name: 'listOfBlocks' }">
                                        Оставьте заявку
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="bgslider" 
                        data-tablet="img/slider/a0.jpg" 
                        data-desctop="img/slider/a0.jpg"
                        data-mobile="img/slider/a0.jpg">
                        </div>
                    </div>
                
                    <!--Slider 2-->
                    <div>
                        <div class="site-slider__content app_slide">
                            <div class="container">
                                <div class="site-slider__title">НОВОЕ ПОКОЛЕНИЕ ТОРГОВЛИ</div>
                                <div class="site-slider__description">
                                    Qo’yliq Katta Bozori — инновационный комплекс, искусно объединяющий в себе<br>
                                    центр современной торговли, возможности для успешных бизнес инвестиций и<br>
                                    качественного досуга.
                                </div>
                                <div class="app_links d-flex flex-wrap pt-3">
                                    <router-link class="button-main-slider ing_prt8" tabindex="0" :to="{ name: 'listOfBlocks' }">
                                        Оставьте заявку
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="bgslider" 
                        data-tablet="img/slider/a1.jpg" 
                        data-desctop="img/slider/a1.jpg"
                        data-mobile="img/slider/a1.jpg">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-video-container p-absolute">
                <img src="../../public/img/slider/a0.jpg" alt="video-image" class="d-block img-cover">
                <div class="image-layer p-absolute w-100 h-100 child-center pointer">
                    <i class="fa-solid fa-play"></i>
                </div>
            </div>
        </section>
        <!-- end Section Slider -->
    </main>
    <!-- <footer></footer> -->
    <!-- mobil-nav-menu -->
    <section class="mobil-nav">
        <div class="mobli-left">
            <div class="haburger click2 ">
                <span class="span2"></span>
                <span class="span3"></span>
            </div>
            <div class="left-login">
                <img src="../../public/img/logo-g.png" alt="">
            </div>
            <div class="len links-blocks">
                <a href="#" class="actione">uz</a>
                <a href="#">ru</a>
                <a href="#">en</a>
            </div>
            <div class="b-top">
                <div class="menu-site">
                    <router-link :to="{ name: 'home' }">
                        <span>Главная</span>
                    </router-link>
                    <router-link :to="{ name: 'blocks' }">
                        <span>Блоки</span>
                    </router-link>
                    <router-link :to="{ name: 'information' }">
                        <span>Информация</span>
                    </router-link>
                    <router-link :to="{ name: 'aboutProject' }">
                        <span>О проекте</span>
                    </router-link>
                    <router-link :to="{ name: 'developer' }">
                        <span>Застройщик</span>
                    </router-link>
                    <router-link :to="{ name: 'gallery' }">
                        <span>Галерея</span>
                    </router-link>
                    <router-link :to="{ name: 'contacts' }">
                        <span>Контакты</span>
                    </router-link>
                </div>
                <div class="tel">
                    <a href="#">+99893 870 99 99</a>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
    export default {
        mounted() {
            var winwidth = $(window).width();

            if (winwidth < 750) {
            $(".bgslider").each(function(a, b) {
                var data_src = $(this).data("mobile");
                $(this).removeAttr("style").css("background-image", "url(" + data_src + ")");
            });
            }

            if (749 < winwidth && winwidth < 1185) {
            $(".bgslider").each(function(a, b) {
                var data_src = $(this).data("tablet");
                $(this).removeAttr("style").css("background-image", "url(" + data_src + ")");
            })
            }

            if (1185 <= winwidth) {
            $(".bgslider").each(function(a, b) {
                var data_src = $(this).data("desctop");
                $(this).removeAttr("style").css("background-image", "url(" + data_src + ")");
            });
            }

            var timerId, i, slide = 1;
            var all_slide = $(".slider_main_block>div");
            var navi = document.createElement("div");

            for (navi.className = "navi", $(".slider_main_block").append(navi), i = 0; i < all_slide.length; i++) {
            var dots = document.createElement("div");
            dots.className = "dots_" + (i + 1),
                $(".navi").append(dots)
            }

            var all_dots = $(".navi>div");

            $(".slider_main_block").removeClass("start");
            $(".slider_main_block>div:first-child").addClass("is-active");
            $(".navi>div:first-child").addClass("is-active");

            Animated();

            setTimeout(function() {
            $(".slider_main_block").removeAttr("style").css("background-color", "#aaa")
            }, 4e3);

            $(document).on("click", ".slider_main_block>div.is-active", function() {
            $(this).parent().hasClass("is-stoped") ? (Animated(), $(this).parent().removeClass("is-stoped")) : ($(this).parent().addClass("is-stoped"), clearInterval(timerId));
            });

            $(all_dots).click(function() {
            slide = $(this).index(),
                clearInterval(timerId),
                slide < all_slide.length && (slide += 1,
                $(all_slide).removeClass("is-active"),
                $(all_slide[slide - 1]).addClass("is-active"),
                $(all_dots).removeClass("is-active"),
                $(all_dots[slide - 1]).addClass("is-active")),
                slide == all_slide.length && ($(all_slide).removeClass("is-active"),
                $(all_slide[slide - 1]).addClass("is-active"),
                $(all_dots).removeClass("is-active"),
                $(all_dots[slide - 1]).addClass("is-active"),
                slide = 0),
                $(this).parent().parent().addClass("is-stoped")
            });

            function Animated() {
            timerId = setInterval(function() {
                slide < all_slide.length && (slide += 1,
                    $(all_slide).removeClass("is-active"),
                    $(all_slide[slide - 1]).addClass("is-active"),
                    $(all_dots).removeClass("is-active"),
                    $(all_dots[slide - 1]).addClass("is-active")),
                slide == all_slide.length && ($(all_slide).removeClass("is-active"),
                    $(all_slide[slide - 1]).addClass("is-active"),
                    $(all_dots).removeClass("is-active"),
                    $(all_dots[slide - 1]).addClass("is-active"),
                    slide = 0)
            }, 6e3);
            }

            if (document.querySelector('.slider_main_block') != null) {
                if (window.innerWidth < 750) {
                    document.querySelector('.slider_main_block').setAttribute('style', 'background-image:url(' + document.querySelector('.slider_main_block').getAttribute('data-mobile') + '); background-size:cover;background-position:bottom center;')
                }
                if (window.innerWidth > 749 && window.innerWidth < 1185) {
                    document.querySelector('.slider_main_block').setAttribute('style', 'background-image:url(' + document.querySelector('.slider_main_block').getAttribute('data-tablet') + '); background-size:cover;background-position:bottom center;')
                }
                if (window.innerWidth > 1184) {
                    document.querySelector('.slider_main_block').setAttribute('style', 'background-image:url(' + document.querySelector('.slider_main_block').getAttribute('data-desktop') + ');background-size:cover;background-position:bottom center;')
                }
            }  

            $('.click').on('click', function() {
                $('.mobil-nav').addClass('active');
                $('body').addClass('body-hidden');
                });
                $('.click2').on('click', function() {
                    $('.mobil-nav').removeClass('active');
                    $('body').removeClass('body-hidden');
            });

            $(document).ready(function() {
                $('.overlay, body').addClass('loaded');
                setTimeout(function() {
                    $('.overlay').css({'display':'none'})
                }, 2000)

                setTimeout(function() {
                    $('.overlay, body').addClass('loaded');
                }, 3000);
            })
        }
    };
</script>

<style lang="scss">
    @import '../../public/css/slider.css';
    @import '../../public/css/index.css';
    @import '../../public/css/mobil.css';
</style>
    
